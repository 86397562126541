import React from 'react'
import { graphql } from 'gatsby'

import GraphQLErrorList from '../components/graphql-error-list'
import BlockContent from '../components/block-content'
import Layout from '../containers/layout'
import SEO from '../components/seo'

export const query = graphql`
  query TermsPageQuery {
    page: sanityPage(_id: { regex: "/(drafts.|)terms/" }) {
      title
      _rawBody
    }
  }
`

// Classes
const HeadingClass = 'dib relative w-100 w-60-ns f2 fw5 mt0 mb2'

const TermsPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = data.page

  if (!page) {
    throw new Error(
      'Missing "Contact" page data. Open the studio at http://localhost:3333 and add "Contact" page data and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO title={page.title} />
      <article>
        <section className="dib relative w-100 bg-white black lh-copy f5 overflow-hidden">
          <div className="db center mw8 ph4 pv6 pv7-l">
            <h1 className={HeadingClass}>{page.title}</h1>
            <div className="dib relative w-100 measure-wide fw4">
              <BlockContent blocks={page._rawBody || []} />
            </div>
          </div>
        </section>
      </article>
    </Layout>
  )
}
TermsPage.defaultProps = {
  data: {
    page: {
      title: 'No title'
    }
  }
}
export default TermsPage
